<template>
  <div class="h-full">
    <div class="h-full pb-16">
      <slot />
    </div>
    <footer class="fixed bottom-0 left-0 z-10 flex w-full shrink-0 flex-col md:transition-[padding-left]" :class="sidebarStore.isExpanded ? 'lg:pl-60' : 'lg:pl-16'">
      <div
        class="relative flex min-h-0 flex-1 justify-between gap-4 border-t border-gray-200 bg-white p-4 py-3 dark:bg-gray-800"
      >
        <slot name="footer" />
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const sidebarStore = useSidebarStore()
</script>
